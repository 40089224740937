import { Routes, Route } from 'react-router-dom';
import Login from './routes/onboarding/Login';
import NewUserType from './routes/onboarding/NewUserType';
import RepoURL from './routes/onboarding/RepoURL';
import DeployKey from './routes/onboarding/DeployKey';

import Home from './routes/dashboard/Home';
import Settings from './routes/dashboard/Settings';
import Alerts from './routes/dashboard/Alerts';
import { DashboardRoot } from './routes/dashboard/DashboardRoot';
import { OnboardingProgressView } from './routes/onboarding/OnboardingProgressView';
import './App.css';

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
  integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
  crossorigin="anonymous"
/>

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/onboarding" element={<OnboardingProgressView />}>
        <Route path="user-type" element={<NewUserType />} />
        <Route path="repo-url" element={<RepoURL />} />
        <Route path="deploy-key" element={<DeployKey />} />
      </Route>

      <Route path="/dashboard" element={<DashboardRoot />}>
        <Route index element={<Home />} />
        <Route path="settings" element={<Settings />} />
        <Route path="alert" element={<Alerts />} />
      </Route>
    </Routes>
  );
}

export default App;
