import http from "../http-common.ts";

export class LoginService {
    public async sendLoginVerificationEmail(email: string): Promise<any> {
        const response = await http.get("/user/login", { 
            data: { 
                user: { 
                    email: "mkchoi212@gmail.com"
                }   
            }
        });

        return response.data;
    }
}