import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Divider } from "@mui/material";
import { Stack } from "react-bootstrap";
import * as Yup from "yup";
import FormTextField from "./FormTextField.js";
import OnboardingCTAButton from "./OnboardingCTAButton";
import { LoginService } from "../../services/LoginService.ts";

import HexLogo from './assets/hex-small.png';
import "../../index.css"

export default function Login() {
    const [isEmailSent, setIsEmailSent] = useState(false)
    const newAccountEmailMessage = "If you have an account, you will receive a login code. Please check your inbox."
    const loginService = new LoginService()

    const sendLoginEmail = async () => {
        const resp = loginService.sendLoginVerificationEmail("asdf")
        console.log(resp)
        setIsEmailSent(true)
    }

    return (
        <div class="bg-[#f5f5f5] h-screen grid grid-cols-5 place-items-center place-content-center place-items-stretch">
            <div class="absolute top-10 left-10">
                <Stack direction="horizontal" gap={2}>
                    <img className="h-10 pb-2" src={HexLogo} alt={HexLogo} />
                    <h5 class="font-bold text-center">hex</h5>
                </Stack>
            </div>

            <div class="col-start-2 col-span-3">
                <h1 class="font-bold text-4xl text-center ">Log in</h1>
                <Formik
                    initialValues={{
                        email: ""
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email("Invalid email addresss")
                            .required("Required")
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        await new Promise((r) => setTimeout(r, 500));
                        setSubmitting(false);
                    }}
                    onChange={() => {
                        console.log('changing');
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        valid
                    }) => {
                        return (
                            <div class="flex place-content-center">
                                <Form class="my-6 max-w-sm center w-full">
                                    <FormTextField
                                        label="Email"
                                        name="email"
                                        type="email"
                                        placeholder="your-company@email.com"
                                        onChange={e => {
                                            handleChange(e)

                                            if (isEmailSent) {
                                                setIsEmailSent(false)
                                            }
                                        }}
                                    />

                                    {
                                        isEmailSent &&
                                        <div class="mt-4">
                                            <Divider class="w-fl py-1" />
                                            <p class="max-w-sm text-center text-md text-gray-600">{newAccountEmailMessage}</p>
                                        </div>
                                    }
                                </Form>
                            </div>
                        );
                    }}
                </Formik>

                <OnboardingCTAButton
                    text={isEmailSent ? "Resend email" : "Continue with email"}
                    onClick={sendLoginEmail}
                />
            </div>
        </div>
    );
}
